






















































import { Vue, Component, Ref } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { SpeedLogSensorQuality } from "@/types/dataQuality/SpeedLogSensorQuality";
import { SpeedThroughWater } from "@/types/dataQuality/SpeedThroughWater";
//  components
import StatusCard from "@/components/dataQuality/speedLog/StatusCard.vue";
import SensorQualityChart from "@/components/dataQuality/speedLog/Charts/SensorQualityChart.vue";
import SpeedComparisonChart from "@/components/dataQuality/speedLog/Charts/SpeedComparisonChart.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/clients/Snackbar.module";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const DataQuality = getModule(DataQualityModule, store);
const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    StatusCard,
    SensorQualityChart,
    SpeedComparisonChart,
  },
})
export default class SpeedLog extends Vue {
  @Ref("SpeedComparisonChart") SpeedComparisonChart!: any;
  @Ref("SensorQualityChart") SensorQualityChart!: any;

  useDerivedSTW = false;
  selectedVessel: ExtendedVessel | null = null;
  query: { imo: number; from: string; to: string } = {
    imo: 0,
    from: moment.utc().subtract(1, "year").format("YYYY-MM-DD"),
    to: moment.utc().format("YYYY-MM-DD"),
  };

  //  @Watchers
  async onSelectedVesselChange(): Promise<void> {
    this.query.imo = this.selectedVessel?.imoNumber || 0;
    await this.fetchSpeedLog();
    await this.fetchDerivedSpeedThroughWater();
    await this.fetchSpeedThroughWater();
  }

  //  @Getters
  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels.filter(vessel => this.hasDataQualityEnabled(vessel));
  }

  get speedLogData(): SpeedLogSensorQuality {
    return DataQuality.speedLogData;
  }

  get derivedSpeedThroughWaterData(): SpeedThroughWater {
    return DataQuality.derivedSpeedThroughWaterData;
  }

  get speedThroughWaterData(): SpeedThroughWater {
    return DataQuality.speedThroughWaterData;
  }

  get speedLogDataLoadingState() {
    return DataQuality.speedLogDataLoadingState;
  }

  get derivedSpeedThroughWaterLoadingState() {
    return DataQuality.derivedSpeedThroughWaterLoadingState;
  }

  get speedThroughWaterLoadingState() {
    return DataQuality.speedThroughWaterLoadingState;
  }

  get speedComparisonChartLoader() {
    return Boolean(DataQuality.speedThroughWaterLoadingState || DataQuality.derivedSpeedThroughWaterLoadingState);
  }

  get sensorQualityStatus(): number {
    if (!this.speedLogData?.data || this.speedLogData?.data.length === 0) return 0;
    const lastSpeedPoint = this.speedLogData.data[this.speedLogData.data.length - 1].bias ?? 0;
    if (lastSpeedPoint >= -5 && lastSpeedPoint <= 5) return 1;
    if (lastSpeedPoint > 10 || lastSpeedPoint < -10) return 3;
    if ((lastSpeedPoint > 5 && lastSpeedPoint <= 10) || (lastSpeedPoint < -5 && lastSpeedPoint >= -10)) return 2;

    return 0;
  }

  get statusCardTooltip(): string {
    return "Based on the relative change between measured STW and derived STW, excluding port and maneuvering data, and averaged over the last 7 days. Small variations are normal, caused by noise in measured STW or sea current data.";
  }

  //  @Methods
  async fetchSpeedLog(): Promise<void> {
    try {
      await DataQuality.fetchSpeedLog(this.query);
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to fetch speed log data" });
    }
  }

  async fetchDerivedSpeedThroughWater(): Promise<void> {
    try {
      await DataQuality.fetchDerivedSpeedThroughWater({ imoNumber: this.query.imo });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to fetch derived speed through water data" });
    }
  }

  async fetchSpeedThroughWater(): Promise<void> {
    try {
      await DataQuality.fetchSpeedThroughWater({ imoNumber: this.query.imo });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to fetch speed through water data" });
    }
  }

  hasDataQualityEnabled(vessel: ExtendedVessel): boolean {
    return vessel.features.some(feature => feature.name === "DataQuality");
  }

  onSensorQualityChartZoom(selection: { from: string; to: string; resetZoom: boolean }): void {
    this.SpeedComparisonChart.zoom(selection);
  }

  onSpeedComparisonChartZoom(selection: { from: string; to: string; resetZoom: boolean }): void {
    this.SensorQualityChart.zoom(selection);
  }

  //  @Hooks
  async created(): Promise<void> {
    await Vessels.refreshExtendedVessels();
    this.selectedVessel = this.extendedVessels[0];
    this.query.imo = this.selectedVessel?.imoNumber || 0;
    await Promise.all([this.fetchSpeedLog(), this.fetchDerivedSpeedThroughWater(), this.fetchSpeedThroughWater()]);
  }
}
